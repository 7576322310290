import { Pagination, Skeleton } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardDetailed from "../../components/Table/CardDetailed";
import {
  getFilterObj,
  getParams,
} from "../../components/Table/components/customTableFunction";
import { fetchTableInit } from "../../redux/actions";
const CardPlusPagination = ({
  type,
  setfilterArr,
  handlingPagination: [globelPage, setglobelpage],
  name
}) => {
  const { page } = globelPage;
  const {
    loading,
    user: {
      [type]: { data, TotalCount },
    },
    filterCount: {
      [type]: { countData },
    },
    filter: { [type]: filter },
  } = useSelector((state) => state.tableReducer) || [];

  const dispatch = useDispatch();
  const { defaultFilterObj, filterObj } = getFilterObj(filter);

  const handlePagination = (currentPage) => {
    setglobelpage((prev) => ({ ...prev, page: currentPage }));
    defaultFilterObj["page"] = currentPage;
    defaultFilterObj["limit"] = 15;
    defaultFilterObj["name"] = name;
    const params = getParams({ type, ...defaultFilterObj }, filterObj, true);
    dispatch(
      fetchTableInit({
        type,
        params,
      })
    );
  };

  console.log("userDeta", data, countData, type);

  return (
    <div className="cardPluspagination">
      {loading ? (
        <div className="manageProfileCard">
          <Skeleton active={true} />
        </div>
      ) : (
        <>
          {" "}
          <div>
            {data && data?.length > 0
              ? data?.map((data, i) => {
                  return (
                    <CardDetailed
                      key={i}
                      data={data}
                      type={type}
                      setfilterArr={setfilterArr}
                    />
                  );
                })
              : null}
          </div>
          {
            // countData?.length && countData[0]?.TotalValue[0]?.count !== 0
            TotalCount !== 0 && (
              <div className="PaginationedItem">
                <Pagination
                  simple
                  current={page ? page : 1}
                  // total={
                  //   countData?.length ? countData[0]?.TotalValue[0]?.count : 1
                  // }
                  total={TotalCount > 0 ? TotalCount : 1}
                  onChange={(e) => handlePagination(e)}
                  defaultPageSize={15}
                />
              </div>
            )
          }
        </>
      )}
    </div>
  );
};

export default CardPlusPagination;
