import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import addIcon from "../../../assets/icons/clarity_settings-line.svg";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { Admin_Users } from "./customFunction";

const ResultCountAndDateFilter = ({
  // totalCount,
  datePickerState: [datePicker, setDatePicker],
  type,
}) => {
  const {
    loading,
    user: {
      [type]: { data, TotalCount },
    },
  } = useSelector((state) => state.tableReducer) || [];
  const { loadingFC } = useSelector((state) => state.tableReducer) || "";
  const user = useSelector((state) => state.profileReducer.success) || [];
  const { RangePicker } = DatePicker;
  const [isDateChange, setIsDateChange] = useState(false);
  const dateFormat = "MMM D, YYYY";
  const CurrentDate = moment(datePicker?.EndDate);
  const startdate = moment(datePicker?.StartDate);
  type = type === "talent" ? "Candidate" : "Employer";

  const handleDataPicker = (e) => {
    setIsDateChange(true);
    e !== null &&
      setDatePicker({
        StartDate: moment(e[0]).format("MM-DD-YYYY"),
        EndDate: moment(e[1]).format("MM-DD-YYYY"),
      });
  };

  const handlingDisableDate = (current) => {
    if (Admin_Users.includes(user?.EmailAddress)) return false;
    else return current > moment() || current < moment().subtract(3, "months");
  };
  return (
    <>
      <div className="rcdFilterContainer">
        <div className="dateRangePickerContainer">
          <div className="userTypeText">Manage {type}</div>
          <div className="dateRangePickerBox">
            <span className="default90days">
              {isDateChange ? "" : "Last 30 days"}
            </span>
            <RangePicker
              disabledDate={(current) => handlingDisableDate(current)}
              bordered={false}
              format="MMM D, YYYY"
              suffixIcon={false}
              className="dateRangePicker"
              defaultValue={[
                moment(startdate, dateFormat),
                moment(CurrentDate, dateFormat),
              ]}
              onChange={(e) => handleDataPicker(e)}
            />
            {/* {type === "Employer" && (
              <span className="addEmployerButton">
                <img src={addIcon} alt="" /> Add Employer
              </span>
            )} */}
          </div>
        </div>
        <div className="userTypeCount">
          Showing{" "}
          {
            // loadingFC
            loading ? (
              <Loader height={"0px"} size={"14px"} display={"inline"} />
            ) : TotalCount > 0 ? (
              TotalCount
            ) : (
              0
            )
          }{" "}
          {TotalCount > 0 ? "results" : "result" }
        </div>
      </div>
    </>
  );
};

export default ResultCountAndDateFilter;
