import { Empty, Skeleton, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./CommonTable.css";
import {
  getColumns,
  getColumnsData,
  getSortTableData,
} from "./components/customTableFunction";

const CommonTable = ({ type, setfilterArr, setFilterArray, filterArray }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sortFilterAdd, setsortFilterAdd] = useState(false);
  const {
    loading,
    user: {
      [type]: { data },
    },
  } = useSelector((state) => state.tableReducer) || [];

  const columnsData = getColumnsData(data, type);
  const [userData, setUserData] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const selectedUserInfo = newSelectedRowKeys.map(
      (selectedUserID) =>
        data?.filter((userInfo) => selectedUserID === userInfo["_id"])[0]
    );
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const sortTableData = (tos, title) => {
    const key = title === "Company" ? title + "Name" : "Name";
    setUserData(
      columnsData.sort((a, b) => getSortTableData(a, b, tos, title, key))
    );
  };

  useEffect(() => {
    setUserData(getColumnsData(data, type));
  }, [data]);

  function removeKeyValuePair(obj, keyToRemove) {
    const newObj = { ...obj };
    delete newObj[keyToRemove];
    return newObj;
  }

  const expensiveCalculation = useMemo(() => {
    if (sortFilterAdd == "AddSort"){
      setFilterArray((prev) => ({ ...prev, ["sort"]: true }));
    }
    else if (sortFilterAdd == "RemoveSort"){
      setFilterArray(removeKeyValuePair(filterArray, "sort"))
    }
  }, [sortFilterAdd]);

  return (
    <>
      <Table
        showSorterTooltip={false}
        rowSelection={rowSelection}
        columns={getColumns(type, sortTableData, setsortFilterAdd, sortFilterAdd)}
        dataSource={loading ? [] : userData}
        className="CommonTableContainer"
        bordered
        pagination={false}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
      />
    </>
  );
};

export default CommonTable;
