import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTableInit } from "../../redux/actions";
import CommonPagination from "../Pagination/CommonPagination";
import CommonTable from "./CommonTable";
import { getFilterObj, getParams } from "./components/customTableFunction";

const TableWithPagination = ({ type, handlingPagination, setfilterArr, setFilterArray, filterArray }) => {
  const filter = useSelector((state) => state.tableReducer.filter[type]) || {};
  const { defaultFilterObj, filterObj } = getFilterObj(filter);

  const dispatch = useDispatch();

  const showTableData = (data) => {
    defaultFilterObj["page"] = data["page"];
    defaultFilterObj["limit"] = data["limit"];
    const params = getParams({ type, ...defaultFilterObj }, filterObj, true);
    dispatch(
      fetchTableInit({
        type,
        params,
      })
    );
  };
  return (
    <>
      <CommonTable
        type={type}
        setfilterArr={setfilterArr}
        setFilterArray={setFilterArray}
        filterArray={filterArray}
      />
      <CommonPagination
        showTableData={showTableData}
        type={type}
        handlingPagination={handlingPagination}
      />
    </>
  );
};

export default TableWithPagination;
