export const types = {
  SEND_REQUEST: "SEND_REQUEST",
  SEND_REQUEST_SUCCESS: "SEND_REQUEST_SUCCESS",
  SEND_REQUEST_FAILURE: "SEND_REQUEST_FAILURE",
  DEFAULT_NAVIGATION: "DEFAULT_NAVIGATION",
  CHECK_EXISTING_USER_INIT: "CHECK_EXISTING_USER_INIT",
  CHECK_EXISTING_USER_SUCCESS: "CHECK_EXISTING_USER_SUCCESS",
  CHECK_EXISTING_USER_FAILURE: "CHECK_EXISTING_USER_FAILURE",
  FETCH_TABLE_INIT: "FETCH_TABLE_INIT",
  FETCH_TABLE_SUCCESS: "FETCH_TABLE_SUCCESS",
  FETCH_TABLE_F_SUCCESS: "FETCH_TABLE_F_SUCCESS",
  FETCH_TABLE_FC_SUCCESS: "FETCH_TABLE_FC_SUCCESS",
  FETCH_TABLE_FAILURE: "FETCH_TABLE_FAILURE",

  SEARCH_CANDIATE_RESULTS: "SEARCH_CANDIATE_RESULTS",
  SEARCH_CANDIATE_RESULTS_SUCCESS: "SEARCH_CANDIATE_RESULTS_SUCCESS",
  SEARCH_CANDIATE_RESULTS_FAILURE: "SEARCH_CANDIATE_RESULTS_FAILURE",

  SEARCH_CANDIATE_CAMPAIGNS: "SEARCH_CANDIATE_CAMPAIGNS",
  SEARCH_CANDIATE_CAMPAIGNS_SUCCESS: "SEARCH_CANDIATE_CAMPAIGNS_SUCCESS",
  SEARCH_CANDIATE_CAMPAIGNS_FAILURE: "SEARCH_CANDIATE_CAMPAIGNS_FAILURE",

  DOWNLOAD_CSV_FILE_INIT: "DOWNLOAD_CSV_FILE_INIT",
  DOWNLOAD_CSV_FILE_SUCCESS: "DOWNLOAD_CSV_FILE_SUCCESS",
  DOWNLOAD_CSV_FILE_FAILURE: "DOWNLOAD_CSV_FILE_FAILURE",
  COLLAPSE_TOGGLE_TRUE: "COLLAPSE_TOGGLE_TRUE",
  COLLAPSE_TOGGLE_FALSE: "COLLAPSE_TOGGLE_FALSE",

  FETCH_PROFILE_INIT: "FETCH_PROFILE_INIT",
  FETCH_PROFILE_REQUEST_SUCCESS: "FETCH_PROFILE_REQUEST_SUCCESS",
  FETCH_PROFILE_REQUEST_FAILURE: "FETCH_PROFILE_REQUEST_FAILURE",

  FETCH_TALENT_USER_DETAILS: "FETCH_TALENT_USER_DETAILS",
  FETCH_TALENT_USER_DETAILS_SUCCESS: "FETCH_TALENT_USER_DETAILS_SUCCESS",
  FETCH_TALENT_USER_DETAILS_FAILURE: "FETCH_TALENT_USER_DETAILS_FAILURE",

  FETCH_USER_CHAT_HISTORY: "FETCH_USER_CHAT_HISTORY",
  FETCH_USER_CHAT_HISTORY_SUCCESS: "FETCH_USER_CHAT_HISTORY_SUCCESS",
  FETCH_USER_CHAT_HISTORY_FAILURE: "FETCH_USER_CHAT_HISTORY_FAILURE",
  CLEAR_USER_CHAT_HISTORY: "CLEAR_USER_CHAT_HISTORY",
  AUTH_USER_TOKEN: "AUTH_USER_TOKEN",
};
